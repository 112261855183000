const mobileNavBtn = document.getElementById('mobile-nav-btn');
const mobileNav = document.getElementById('mobile-nav');

mobileNavBtn.addEventListener('click', () => {
    mobileNavBtn.classList.toggle('open');
    mobileNav.classList.toggle('open')
})

const secondaryNavs = document.querySelectorAll('.mobile-nav-trigger');

secondaryNavs.forEach((item) => {
    item.addEventListener('click', () => {
        secondaryNavs.forEach(item =>
            item.nextElementSibling.classList.remove('active'))

        item.nextElementSibling.classList.add('active');
    })
})

const backBtn = document.querySelectorAll('.mobile-secondaryNav__back');

backBtn.forEach(item => item.addEventListener('click', () => {
    secondaryNavs.forEach(item => item.nextElementSibling.classList.remove('active'))
}))


const siblingNavMenuToggle = document.getElementById('siblingNavMenuToggle');
const siblingNavMenu = document.getElementById('siblingNavMenu')

if(siblingNavMenu) {
    siblingNavMenuToggle.addEventListener('click', () => {
        siblingNavMenuToggle.classList.toggle('active')
        siblingNavMenu.classList.toggle('active')
    })
}


const mobileSearchBtn = document.getElementById('mobileSearchBtn');
const mobileSearch = document.getElementById('mobileSearch');

mobileSearchBtn.addEventListener('click', () => {
    mobileSearch.classList.toggle('active')
})