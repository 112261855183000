const Flickity = require('flickity');
const logoSlider = document.querySelector('.logoSlider');
const accordionCarousel = document.querySelectorAll('.accordionCarousel');
const headerSlider = document.querySelector('.headerSlider');

if (logoSlider) {
    const flkty = new Flickity( logoSlider, {
        // options
        cellAlign: 'left',
        wrapAround: true,
        pageDots: false,
    });

    // Set initial position to be 0
    flkty.x = 0;

    // Start the marquee animation
    play();

    // Main function that 'plays' the marquee.
    function play() {
        flkty.x = flkty.x - 1.1;
        flkty.settle(flkty.x);
        window.requestAnimationFrame(play);
    }

    window.addEventListener('load', function() {
        flkty.resize();
    });
}

if (accordionCarousel) {
    accordionCarousel.forEach(item => {
        const flkty = new Flickity(item, {
            // options
            cellAlign: 'left',
            pageDots: false,
        });

        window.addEventListener('load', function() {
            flkty.resize();
        });
    })
}

if (headerSlider) {
    const flkty = new Flickity(headerSlider, {
        // options
        cellAlign: 'left',
        wrapAround: true,
        groupCells: true,
        autoPlay: 5000,
        imagesLoaded: true
    });

    window.addEventListener('load', function() {
        flkty.resize();
    });

    Flickity.prototype._createResizeClass = function() {
        this.element.classList.add('flickity-resize');
    };

    Flickity.createMethods.push('_createResizeClass');

    var resize = Flickity.prototype.resize;
    Flickity.prototype.resize = function() {
        this.element.classList.remove('flickity-resize');
        resize.call( this );
        this.element.classList.add('flickity-resize');
    };
}