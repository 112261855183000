const inputs = document.querySelectorAll('.field__input');

inputs.forEach((item) => {
    item.addEventListener("input", () => {
        let field = item.closest(".field");
        if (item.value) {
            field.classList.add('field--not-empty');
        } else {
            field.classList.remove('field--not-empty');
        }
    })
})