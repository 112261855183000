let accordions = document.querySelectorAll('.accordion__heading');

if (accordions) {
    accordions.forEach( (item) => {
        item.addEventListener('click', () => {
            item.nextElementSibling.classList.toggle('active')

            let caret = item.querySelector('img')
            caret.classList.toggle('caret-up');
        })
    })
}